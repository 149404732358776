@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
.page-body{
    /*padding-left:2%;
    padding-right: 2%;*/
    height:95%;
    max-height:95%;
    overflow-y: auto; 
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
}
/*.alternate-border-color:nth-child(2n){
  border-left:1px solid #c4caff;
  border-radius: 5px;
}
.alternate-border-color:nth-child(2n+1){
  border-right:1px solid #c4caff;
}*/
.good-box-shadow{
  box-shadow: 0.0625rem .125rem .0625rem rgba(0,0,0,.15),0 -.0625rem .125rem 0 rgba(0,0,0,.1)
}

/*input:hover{
  border:2px solid #C2B3F7;
}
textarea:active{
  border:2px solid #C2B3F7;
}*/
tr:hover {
  background-color: #f7f7f7;
}
.main-header {
  align-items: center;
  background: white;
  display: flex;
  width:100%;
  height: 100%;
}


/*dont change this */
.header-dropdown{
  position: relative;
  display:block;
  z-index: 10;
  padding:1px;
  padding-right: 3px;
  cursor: pointer;
}
#header-menu{
  position: absolute;
  background-color: white;
  border: 1px solid #f8f9fc;
  width:200px;
  border-radius: 5px;
-webkit-box-shadow: 0 0 10px #eeeeee;
        box-shadow: 0 0 10px #eeeeee;  
  background-color: white;
}
.dropdown-item-menu:hover{
  background-color: #eeeeee;
  font-weight: 600;
}
/* Dont change this */
.dropdown-item-menu{
  font-weight: 500;
  padding-left:20px;
  padding-right:20px;
  padding-top:10px;
  padding-bottom:10px;
  text-decoration:none;
  font-size:14px;
  color:black;
  display: block;
  font-family: zubilo,Roboto,Helvetica,sans-serif,Lato
}
.search-wrapper {
  position: relative;
  margin-left: 10%;
  border-bottom: 1px solid #c4c4c4;
  width:30%;
}
.search-wrapper input:focus{
  outline: none;
  border-bottom: 1px solid rebeccapurple;
}
.search-wrapper img {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 20px;
}
.header-right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-right button {
  margin-right: 10px;  
}

.search-input{
  border: 0;
  padding: 8px 10px 8px 40px;
  box-sizing: border-box;
  border-radius: 22px;
  width: 100%;
  outline: none;
  /*background-color: rgb(250,251,252);*/

}
.search-input:focus-within{
  outline: none;
}
.btn-icon img {
  transform: rotate(90deg);
}

.middle-frame {
  background-color: silver;
  padding: 0 20px;
  margin-right: 2%;
  margin-left:1%;
  margin-top:1%;
  border-radius:5%;
  height:95%;
  width:30%;
}
.down-triange{
      width: 0;
      height: 0;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      border-top: 100px solid red;

}

label {
  margin: 0 0 5px;
}
.flex{
  display: flex;  
}

.list-image {
  width: 25px;
  height: 25px;
  min-height: 25px;
  min-width: 25px;
  border-radius: 50%;
}
.contact-wrapper {
  padding: 20px 8px;
  border-top: 1px solid #e6e6e6;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-top: 5px;
  padding: 5pxs;
}
.contact-wrapper h5 {
  font-size: 19px;
  margin: 0 0 15px;
}

.contact-wrapper textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
}


.contact-display-left-box{
  padding: 0 25px;
  width:50%;
  left:50%;
  top:0%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
}
.contact-display-left-box-dropdown{
  margin-top:5%;
  padding-left:2px;
  font-size: 16px;
  font-weight: bolder;
}
.contact-display-left-box-dropdown ul{
  list-style-type: none;
  font-weight: normal;
  font-size: 15px;
}
.contact-display-dropdown-menu{
  font-size: 15px;
  font-weight: normal;
  padding-left:5px;
  padding-top:2px;
}
.contact-display-dropdown-menu:hover{
  font-weight: bolder;
  background-color: grey;
}
#contact-details-drop-down{
  border:1px;
  width: 15%;
  padding:2px;
  background-color: white;
  z-index:   1;  
  display:none;
  position: absolute;
}

.contact-filter-icon{
  height: 25px;
  width:  25px;
  padding-left: 5px;
  margin-left:5px;
  background-color:white;
}
.contact-name-search-dropdown{
  position:absolute;
  background-color: blue;
}


.create-closebtn{
  position: absolute;
  top: 10px;
  right: 40px;
  font-size: 30px;
  color: black;
  text-decoration: none;
}
.create-form-label{
  font-weight: 500;
  font-size:16px;
  color:black;
  font-family: -apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}
.ss-button{
 background-color:#006fd2;
 /*background-color:#2196f3;*/
 /*background-color: #28a745;*/
 font-weight:600;
 font-size: 14px;
 padding-top:5px;
 padding-bottom:5px;
 padding-left:10px;
 padding-right:10px; 
 color:white;
 text-align:center; 
 font-family: sans-serif;
 border-radius:5px;
 border:1px solid rgb(76, 120, 237);
 margin-right: 10px;
 cursor: pointer;
 /*background-color: rgb(225, 40, 90);*/
 /*border-color: rgb(215, 55, 107);*/
}

.ss-button:hover{
  background-color: #2559E5;
  font-weight: bolder;
}

.create-button{
  margin-top: 30px; 
  background-color: rgb(76, 120, 237);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:15px;
  padding-right:15px;
}
.create-button:hover{
  background-color: #2559E5;

}
.drop-area-wrapper{
  /*margin-top: 10px;*/
  /*width:125px;*/
  box-shadow:  0px 2px 8px 0px grey;
  border: 2px solid #F5F5F5;
  height:125px;
  background-color:#F5F5F5;
  margin-left:1%;
  position:relative;
  /*text-align: center;*/
}
.create-display-image-wrapper{
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding:20px;
  margin-top:1rem;
  padding-top:10px;
  width:90%;
  background-color:   #F5F5F5;;
  border:1px dotted black;


}
#drop-area{
  width:125px;
  height: 125px;
  text-align:center;
  font-size: 12px;
  background-size: 125px 125px;
}
.blue-color-dark{
  color: #0052cc;
}
.create-line{
  right:5%;
  position: relative; 
  /*border-width:1px solid black;*/
  width:90%;
  height:10px;
  content: "";

     border-bottom: 1px solid rgb(49, 68, 105);
     margin: auto;  
}

.background{
  /*background-color: rgb(250,251,252)*/
  background-color: rgb(227, 220, 220)
}
.contact-details-list{
  margin-top:5px;
  
  padding-bottom:5px;
  background-color: rgb(248, 249, 250);
}
.contact-details-card{
  padding:2px;
  background-color: white;
  width:98%;
  left: 1%;
  right:1%;
  position: relative;
  margin-top:1%;
  border-radius: 5px;
}
.contact-details-card-profile {
  position: relative;
  width: 100%;
  height:8%;
  margin-bottom: 10px;
  align-items: center;
  background-color:grey;
}

.create-display-image-close-button{
  position: absolute;
  right:0px;
  top:0px;
  font-size:25px;
  bottom:5px;
  cursor: pointer;
  float:left;
  font-weight: 400;
}


.create-display-image img{
  border-radius: 30%; 
}

.display-flex-wrapper{
  display:flex;
  flex-direction:row;
  margin-top:12%;
  margin-left:2%;
  margin-right:10%;
  padding:10px; 
  width:70%;
  margin-left:15%; 

}
.contact-display-right-box{
  width:50%;
  box-shadow: 0px 4px 6px rgb(51 51 51 / 20%);  
  min-height: calc(100vh - 56px);
  background-color: #D7EDDB.;
  height: 90%;
  /*background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);*/
}
.display-image{
  height:120px;
  width:120px;
  padding-left:10px;
  padding-right:2px;
  vertical-align: center;
  color:rgb(98, 111, 133);
}
.display-image>div>img{
  border-radius:30%;
}
.display-name{
  display:flex;
  flex-direction: column-reverse;
  margin-left:1%;
  margin-bottom:30px;
  padding:1%;
  color: #1A1C1D;
  font-size:14px;
}
.display-header-button{
  margin:5px;
  width:75px;
  border-radius:6px;
  font-weight: 800;
  height:30px;
  padding-left:10px;
  padding-right:10px;
  padding:5px;
  background-color:rgb(98, 111, 133);
  color:white;
  border:2px solid white;
}
.lato-style{
  font-family:Lato;
  font-weight:normal;
}


#img-view{
  width:100%;
  height:100%;
  border-radius:2px;
  border: 2px dashed black;

}

.apply-pagination {
  right:1%;
  bottom:0%;
  position: absolute;
  height:4%;
  color: black;
}
.apply-pagination-active{
  background-color: #4CAF50;
  color:white;
  border: 1px solid #4CAF50;
}
.apply-pagination a {
  color: black;
  float: left;
  padding: 2px 8px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight:500;
}
.apply-pagination a:hover{
  font-weight:600;
}

.apply-pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}
.red-color{
  color: red;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
}
.add-ellipsis{
 overflow: hidden;
 text-overflow: ellipsis; 
 max-width:200px;
 white-space: nowrap;
}
.add-width-ellipsis{
 overflow: hidden;
 text-overflow: ellipsis; 
 width:400px;
 white-space: nowrap;
}
.create-contact-flex{
  flex: 0 0 50px;
}


.create-wrapper{  
  padding: 20px;
  min-width:400px;
  max-width:1000px;
  width:50%;
  height: 100%;  
  right:0%;
  top:1%;
  right:5px;
  position:fixed; 
  border-radius: 3px;
  box-shadow: 2px 2px 5px 2px;
  background-color: #fdfdfd;
  z-index: 100 !important;  
  transition-timing-function: ease-out;
  overflow-y: auto;  
}


.create-wrapper input::placeholder {
  font-weight: normal;
  font-size: small;
  opacity: 0.5;
  color: black;
}
input[type="checkbox"]{
  height: 15px;
  /*border:3px solid    #999999;*/
}

 input {
  border-radius: 5px;
  border-width : 1px;
  font-size: 0.9rem;
  height: 33px;
  /*opacity: 0.5;*/
  /*border-color:rgb(49, 68, 105);*/
  border-color:#909196;
  color:black;
  font-weight:500;
  border-style: double;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
}
.create-wrapper textarea {
  border-radius: 5px;
  border-width : 1px;
  font-size: 0.9rem;
  height: 36px;
  padding:5px;
  border-radius: 5px;
  /*border-color:rgb(49, 68, 105);*/
  border-color:#909196;
  color:black;
  font-weight:500;
  border-style: double;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
}
.create-wrapper input:hover{
  outline:blue;
}
.create-wrapper h8{
  color:rgb(49, 68, 105);
}

.dashboard-dropdown-content{
  width:300px;
  position:absolute;
  max-height:300px;
  overflow-y: scroll;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important;   
}
.create-dropdown-content{
  width:90%;
  color:#3c3c3c;
  position:absolute;
  max-height:300px;
  overflow-y: auto;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important; 
}
.create-dropdown-content a {
  color: black;
  padding-left:   20px;
  padding-right:  20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  display: block;
  z-index: 100 !important;
  position:relative;
  background-color: white;
  width:100%;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis; 
  font-size:14px;
  font-weight: 600;
  cursor: pointer;
  font-family: Poppins,Helvetica,sans-serif,Arial,verdana;  
  -webkit-box-shadow: 0 0 1px 1px #eeeeee;
        box-shadow: 0 0 1px 1px #eeeeee; 
  color:#313638; 
}
.create-group-dropdown-content {
  min-width:250px;
  overflow-x: ellipsis;
  white-space: nowrap;;
}
.create-dropdown-content a:hover{
  background-color: #eeeeee;;
}
.team-selected-agents-box{
  margin-top:20%;
  display: flex;
  flex-direction: column;
}

.contact-group-selected-contacts-box{
  margin-top:20%;
  display: flex;
  flex-direction: column;
  background-color:  #e9eef6;
  padding:20px;
}
.contact-group-selected-contact{
  border-radius: 3px;
  border: 1px solid #6f7ea0;
  background: rgb(250,251,252);
  padding:7px 12px;
  padding-right:20px;
  margin-top:10px; 
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-x: auto; 
  width:90%; 
  min-width:500px;
  white-space: nowrap; 
  font-size: 12px;
  font-weight: 800;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif; 
}
.company-group-selected-companies-box{
  margin-top:20%;
  display: flex;
  flex-direction: column;
}
.company-group-selected-company{
  border: 1px solid black;
  background: rgb(250,251,252);
  padding:5px;
  margin-top:10px;
  font-weight: 600;
}

.create-error{
  color:black;
}
.create-error:before{
    content:*;
    color: red;
}

.custom-select{
  height:38px;
  min-width:550px;
  width:60%;
}
.business-calender-select{
  width:50px;
  height:25px;
  margin-top:20px;
  margin-left:20px;
}
.holidays-input-box{
  display: block;
  width:60%;
  min-width:550px;
  height:38px;
  margin-bottom:10px;
  padding-left: 10px;
}
.business-calender-create-right-box{
 padding-left:5px;
 padding-top:50px;
 height: calc(100vh - 75px); 
 width:20%;
 max-width: 20%;
 background-color:white;
-webkit-box-shadow: 0 0 5px 5px #eeeeee;
        box-shadow: 0 0 5px 5px #eeeeee;
 color:black;   
 overflow-y: auto;
}

.custom-form-builder{
  left:50px;
  height:100%;
  width:100%;  
}
.custom-form-builder form{
  display: flex;
}
.custom-form-builder input{
  /*width: 90%;*/
}
.bh-dropdown-content a{
  width:90%;
  min-width: 450px;
  position:absolute;
  padding-top: 10px;
  max-height:300px;
  overflow-y: auto;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important; 
  cursor: pointer;  
}

.phone-number-configure-dropdown{
  display: none;
  position:absolute;
  z-index: 100;
  font-size:14px;
  font-weight:600;
  max-height:400px;
  overflow-y: auto;
    -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important; 
  width:100%;
  padding-left: 3%;
}
.configure-phone-dropdown-content {
  display: inline-block;
  background-color: white;    
  align-items: center; 
  position: relative;
}
.phone-number-configure-dropdown a{
  width:100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  text-decoration: none;
  display: block;  
  color:black;
  background-color:#ebedf0;
  text-overflow: ellipsis;
  cursor:pointer;
  white-space: nowrap;
  background-color: white;
}
.phone-number-configure-dropdown a:hover{
background-color: #f5f5f5;
}
.center-screen {
position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
}
.custom-modal-box{
  border:1px solid grey;
}
.multiSelectContainer .searchWrapper{
  width:95%;
  margin-left: 0px;
}

.cancel-button {
    background-color: red;
    color: white;
    border: none;
    font-size: 22px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: inline;
    /*align-items: center;*/
    /*justify-content: center;*/
    text-align: center;
    vertical-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.cancel-button:hover {
    background-color: darkred;
    transform: scale(1.1);
}

.cancel-button:focus {
    outline: none;
}


@media only screen and (max-width: 630px){
  .header-filter-name{
   display:none;
  }
}

@media only screen and (max-width: 1000px) {
  #drop-area{
    height:75px;
    width:75px;
    background-size:cover;
  }
  .drop-area-wrapper{
    height:75px;
  }
}


         /*.table-nowrap td, .table-nowrap th {
            white-space: nowrap;
            
         }
         .table-borderless>:not(caption)>*>* {
            border-bottom-width: 0;
         }
         .table>:not(caption)>*>* {
         padding: 0.75rem 0.75rem;
         background-color: var(--bs-table-bg);
         border-bottom-width: 1px;

         box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
         }
         .avatar-sm {
         height: 2rem;
         width: 2rem;
         }
         .rounded-circle {
         border-radius: 50%!important;
         }
         .me-2 {
         margin-right: 0.5rem!important;
         }
         img, svg {
         vertical-align: middle;
         }
         a {
         color: #3b76e1;
         text-decoration: none;
         }
         .badge-soft-danger {
         color: #f56e6e !important;
         background-color: rgba(245,110,110,.1);
         }
         .badge-soft-success {
         color: #63ad6f !important;
         background-color: rgba(99,173,111,.1);
         }
         .badge-soft-primary {
         color: #3b76e1 !important;
         background-color: rgba(59,118,225,.1);
         }
         .badge-soft-info {
         color: #57c9eb !important;
         background-color: rgba(87,201,235,.1);
         }*/





/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    /* Styles for extra small devices */
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    /* Styles for small devices */
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    /* Styles for medium devices */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    /* Styles for large devices */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* Styles for extra large devices */
}

/* Ultra-wide devices (4K screens, 1600px and up) */
@media (min-width: 1600px) {
    /* Styles for ultra-wide devices */
}

