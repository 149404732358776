.dashboard-count{
	background-color:#fafbfd;
	position: relative;
	border: 1px solid 	#CDCDCD;
	color: black;
	line-height: 1.5;
	text-align: center;
	width:max-content;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.MuiPaper-root{
	height:300px;
	width:400px;
	font-size:10px;
	padding:1px;
}
.MuiButtonBase-root{
	padding:1px;
}



.overview {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.overview .card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    flex: 1;
    align-items: center;
}

.overview .card h3 {
    margin-bottom: 15px;
}

.overview .card p {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
}

.ticket-list {
    margin-bottom: 20px;
}

.ticket-list table {
    width: 100%;
    border-collapse: collapse;
}

.ticket-list th, .ticket-list td {
    padding: 10px;
    border: 1px solid #bdc3c7;
    text-align: left;
}

.ticket-list th {
    background-color: #34495e;
    color: #ecf0f1;
}

.ticket-list tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ticket-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.ticket-form h2 {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input, .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
}

.ticket-form button {
    background-color: #16a085;
    color: #ecf0f1;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.ticket-form button:hover {
    background-color: #1abc9c;
}
.overview .card h3 {
    margin-bottom: 15px;
        
}


.dashboard-container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 10px;
        }

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}

@media (min-width: 640px) {
    .dashboard-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .dashboard-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.dashboard-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

 .dashboard-card   h3 {
        color: #6b7280;
        font-size: 16px;
        margin-bottom: 5px;
    }

 .dashboard-card  p {
            font-size: 24px;
            font-weight: bold;
        }
/*
..dashboard-circle {
    width: 50px;
    height: 50px;
    background: conic-gradient(#f97316 65%, #e5e7eb 0%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}*/

.dashboard-circle-container {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.dashboard-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
      var(--fill-color, red) calc(var(--percentage) * 1%),
      var(--background-color, lightgray) calc(var(--percentage) * 1%)
    );
}