.toolbox .form-element {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #e9ecef;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.form-builder {
    min-height: 400px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height:500px;
    overflow-y: scroll;
}

.form-builder form .form-group {
    margin-bottom: 15px;
}
