.create-call-route-wrapper{
  overflow-y: scroll;
  background-color:white;
  height: calc(100vh - 135px);
  overflow-y: scroll;
  margin-left:50px;
  width:75%;
  border-bottom:1px solid #F5F5F5;
  border: 5px solid #F5F5F5;
}
.create-call-route-line{
  right:5%;
  position: relative;   
  height:10px;
  content: "";
  border-bottom: 1px solid rgb(49, 68, 105);
  margin: auto;  
}
.create-call-route-select{
  border-left:0;
  border-top:0;
  border-right:0;
  margin-left: 2%;
  width:250px;
  height:28px;
  font-size:12px; 
  display:inline-block; 
}
.create-call-route-input {
  border-left:0;
  border-right:0;
  border-top:0;
  outline:none;  
  box-sizing: border-box;
  width:250px;
  height:28px;
  display: block;
  font-size: 12px;
}
.create-call-route-input:focus{
  outline: none;
  border:none;
}
.create-call-route-input:active{
  outline: none;
  border:none;
}
.create-call-route-input-dropdown-content {
  display: inline-block;
  background-color: white;  
  z-index:100;
  align-items: center; 
  /*width:100%;*/
  /*margin-left:10%;*/
  position: relative;
  
}
.create-call-route-input-dropdown-content a {
  width:250px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  text-decoration: none;
  display: block;  
  /*background-color: #f1f1f1;*/
  z-index:100;
  text-overflow: ellipsis;
  color:white;
  cursor:pointer;
  white-space: nowrap;
}
.create-call-route-input-dropdown-content a:hover 
{
  background-color:#ebedf0;
}
.create-call-route-input-dropdown-menu{
  display: none;
  background-color: white;
  position:absolute;
  z-index: 100;
  width:250px;
  font-size:14px;
  font-weight:600;
  max-height:250px;
  overflow-y: auto;
}
.create-ivr-input-dropdown-content a{
  width:430px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  text-decoration: none;
  display: block;  
  color:black;
  background-color:#ebedf0;
  text-overflow: ellipsis;
  cursor:pointer;
  white-space: nowrap;
}
.create-ivr-input-dropdown-content a:hover 
{
  background-color: #f5f5f5;
}
.create-ivr-input-dropdown-content {
  
  align-items: center; 
  position: relative;
  width:450px;
  margin-left:30px;
}
.create-ivr-input-dropdown-menu{
  position:absolute;
  z-index: 100;
  width:450px;
  font-size:14px;
  font-weight:600;
  max-height:400px;
  overflow-y: auto;
}
.create-call-route-input:focus {outline: 2px solid #ddd;}
.create-call-route-input-dropdown {
  position: relative;
  display: inline-block;
  bottom:21px;
  margin-left: 3%;
  /*width:100%;*/
}
.create-call-route-flex{
  display: flex;
  flex-direction: row;
}
.call-route-description{
  display: inline-block;
}
@media only screen and (max-width: 910px) {
.call-route-description{
  display: none;
} 
.create-call-route-wrapper{
  margin-left: 5px;
  width:100%;
} 
}
