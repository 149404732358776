.header-actions .d-flex .search-bar {
    max-width: 300px;
    margin: 0;
}.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 8px 16px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}.search-bar:hover {
    border-color: #ccc;
}

.search-bar:focus-within {
    border-color: #aaa;
}
.ss-article{
	font-family: 'Georgia', serif; /* Elegant, readable font */
    font-size: 18px;
    line-height: 1.8;
    background-color: #f5f5f5; /* Light background for reduced eye strain */
    color: black;
    margin: 0;
    padding: 0;        
}
.header-actions .d-flex .search-bar {
    max-width: 300px;
    margin: 0;
}.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 8px 16px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}.search-bar:hover {
    border-color: #ccc;
}

.search-bar:focus-within {
    border-color: #aaa;
}
.sidebar {
    width: 100%;
    padding: 15px 15px 0px;
    border-right: 1px solid #e0e0e0;
    max-width: 320px;
    min-width: 320px;
}
.menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-item a {
    text-decoration: none;
    color: black;
    display: block;
    font-size: 16px;
    padding: 5px 15px 5px 40px;
    border-radius: 5px;
    
}


.dropdown-menu {
    list-style: none;
    padding-left: 0px;
    margin: 0;
    display: none;
}


.dropdown-menu li {
    padding: 0px 0px;
}

.dropdown-menu li a {
    padding: 5px 15px 5px 55px;
    font-size: 16px;
}

.dropdown-toggle {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M44 108a3.988 3.988 0 0 1-2.828-1.172 3.997 3.997 0 0 1 0-5.656L78.344 64 41.172 26.828c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40a3.997 3.997 0 0 1 0 5.656l-40 40A3.988 3.988 0 0 1 44 108z" fill="%23000000" opacity="1" data-original="%23000000" class=""></path></g></svg>');
    background-size: 18px;
    height: auto;
    background-repeat: no-repeat;
    background-position: center left 17px;
}

.menu-item.active .dropdown-toggle {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z" fill="%23000000" opacity="1" data-original="%23000000"></path></g></svg>');
}

.menu-item a:hover , .dropdown-menu li:hover a {
    background-color: rgb(234, 227, 253);
}

.menu-item.active .dropdown-menu {
    display: block; 
    position: relative;
}
.link_text.active {
    background-color: #ececec;
}
.article-content{
    font-family: 'Arial', sans-serif;
  line-height: 1.6;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0 1rem;
}
.article-content h1,h2,h3,h4,h5{
    color: #2c3e50;
}
.article-content h1 {
  font-size: 1.5rem;
  font-weight: bold;
  /*color: #2c3e50;*/
  color:#2b2f55;
  margin-bottom: 0.5rem; 
  padding-bottom: 0.5rem;
}

.article-content h2 {
  font-size: 1.75rem;
  font-weight: bold;
  /*color: #34495e;*/
  color:#2b2f55;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.article-content h3 {
  font-size: 1.5rem;
  color: #555;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.article-content  pre{
    margin:0;
}
.article-content p{
	line-height: 1.5;	
	font-size: 1.1875rem;	
    letter-spacing: 1px;
	font-family: "GreycliffCF", "helvetica", "arial", "sans-serif";
    font-weight: 400;
    color: #2b2f55;
}
.article-content ul li p {
    margin-top: 0;
}
.article-content div{
    line-height: 1.5;
    font-size: 1.1875rem;    
    letter-spacing: 1px;
    font-family: "GreycliffCF", "helvetica", "arial", "sans-serif";
    font-weight: 400;
    color: #2b2f55;
}
.article-content li, ul{
    list-style: disc;
}
#ticket-create-editor ul li{
    list-style: disc;
    line-height: 1.5;
}
#ticket-create-editor ol li{
    list-style: decimal;
    line-height: 1.5;
}

.article-content p:empty{
    font-size:0px;
}
.article-content {
    padding: 15px 35px 10px 15px;
}

.margin-bottom-10 {
	margin-bottom: 10px
}


.padding-30 {
	padding: 30px;
}

.padding-20 {
	padding: 20px;
}


hr.style-transparent {
	border: 0;
	height: 0;
	border-top: 1px solid #ffffff;
	border-bottom: 1px solid #ffffff;
}


.article-info {
	padding: 5px 10px 5px 5px;
	border-top: 1px solid #f0eeee;
	border-bottom: 1px solid #f0eeee;
	background-color: #f9f8f8;
}

.article-info a {
	margin-left: 5px;
	color: #676767;
	text-decoration: none;
}

.article-info a:hover {
	color: #2e2e2e;
	text-decoration: none;
}

.article-info i {
	margin-right: 3px;
}

.art-date {
	display: inline-block;
	margin-left: 10px;
	padding: 3px 0px 3px 0px;
}

.art-category {
	display: inline-block;
	margin-left: 10px;
	padding: 3px 0px 3px 0px;
}





.article-heading {
	font-size: 25px;
	/*padding: 15px 15px 10px 20px;*/
  	text-overflow: ellipsis;
	color: #303030;
	font-weight: bold;
}

.article-heading i {
	margin-right: 5px;
}

.article-heading a {
	color: #303030;
	text-decoration: none;
}

.article-heading a:hover {
	color: #337ab7;
	text-decoration: none;
}

.breadcrumb-container {
	margin: 0px 0px 20px 0px;
}

.breadcrumb li a {
	color: #222222;
	font-weight: bold;
}

.breadcrumb li a:hover {
	color: #155469;
	text-decoration: none;
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #e0e0e0;
    }

    main {
        width: 100%;
    }

    .sidebar {
        padding: 15px 15px 20px;
        min-width: 320px;
        max-width: 100%;
    }
    main {
        margin-top: 30px;
    }
    
    .header-nav .d-flex {
        flex-wrap: wrap;
        justify-content: center;
    }

    .header-nav .logo {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        justify-content: center;
    }
    .logo img {
        width: 100%;
        max-width: 200px;
    }

}


@media screen and (max-width: 575px) {
    .header-actions .d-flex .search-bar {
        max-width: 100%;
    }
    .header-actions .d-flex {
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
    }
}




