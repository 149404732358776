.chat-profile{
  display: flex;
}
.chat-profile-img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.chat-profile-img img {
  object-fit: cover;
}

.chat-profile-details {
  flex: 1;
  padding-left: 10px;
}
.chat-search-wrapper {
  position: relative;
  padding: 15px 0 10px;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 10px;
  width:100%;
}

.chat-search-wrapper img {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 20px;
}

.chat-search-wrapper input{
  border-radius: 5px;
  width: 100%;
}

.chat-message-header {
  border-radius: 15px 15px 15px 0;
  display: inline-block;
  padding: 8px 14px;
  background: #d5e5fd;
}



.chat-left-box .Card-info {
  background-color: #fff;
  margin-top: 10px;
  border-bottom: 2px solid rgb(195, 195, 195);
  border-radius: 5px;
}
.chat-header-info .chat-main-header {
  border-radius: 15px 15px 15px 0;
  display: inline-block;
  padding: 8px 14px;
  color:white;
  font-size: 14px;
  width:40%;
}

.chat-profile-details {
  flex: 1;
  padding-left: 10px;
}

.chat-profile-details h2 {
  font-size: 16px;
  font-weight: 800;
  margin: 0;
  color: white;
}

.chat-profile-details .edit-button {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
}

.chat-profile-details .edit-button img {
  max-width: 20px;
}
.chat-profile-details ul {
  display: flex;
  flex-direction: row;
  padding-left: 0;
}

.chat-profile-details button {
  margin-left: 90px;
}
.chat-profile-details ul {
  display: flex;
  flex-direction: row;
  padding-left: 0;
}

.chat-profile-details button {
  margin-left: 90px;
}
.chat-time p {
  font-size: 11px;
  color: #9a9a9a;
  margin: 0;
}

.chat-time {
  text-align: end;
}

.chat-time span {
  font-size: 11px;
  text-align: center;
  border-radius: 50%;
  background-color: #0073f7;
  border: 0 solid gray;
  display: inline-block;
  width: 15px;
  height: 15px;
  color: #fff;
}

.chat-window .chat-left-box {
  padding: 0 25px;
  box-shadow: 0px 4px 6px rgb(51 51 51 / 20%);
  max-width: 320px;
  max-height: 100%;
  height: 100%;
  background-color: #e6eaeb;
  width:320px;
  position: absolute;
}


.chat-middle-box {
  margin: 0 20px;
  width:calc(100% - 350px);
  left:320px;
  position: absolute;
  height: 100%;
}

.chat-window .chat-middle-box {
  flex: 1;
  margin: 0 20px;  
  /*background-color: #e6eaeb;*/


}
.chat-header-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  /*background-color: #333;*/
  margin-right: 15px;
}
.chat-header-row {
  display: flex;
  flex-direction: row;
  max-width: 50%;
  word-wrap: break-word;
}

.chat-wrapper-items {
  padding: 30px;
  overflow-y: scroll;
  max-height: 40vh;
  display: flex;
  flex-direction: column;
}



.chatbibox-wrapper {
  box-shadow: 0 0 5px rgb(51 51 51 / 21%);
  border-radius: 10px;
  overflow: hidden;
}

.chat-wrapper-items.send {
  background-color: #eee;
  border-radius: 7px;
}
.chat-wrapper .chat-wrapper-items {
  overflow-y: scroll;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
}
.ChatMyMessage .chat-header-info {
  margin-left: auto;
  flex: auto;
}



.ChatMyMessage {
  margin-left: auto;
}

 .ChatMyMessage .chat-header-info .main-header {
  background-color: #0073f7;
  color: #fff;
  border-radius: 15px 15px 0px 15px;
  padding: 5px 10px;
}
.chat-window {
  display: flex;
  background-color: #fff;
}


.uplode-file {
  display: flex;
  flex-wrap: wrap;
}

.uplode-file {
  margin: 20px 0 0;
}

.uplode-file a {
  text-decoration: none;
  color: #000;
}

.uplode-file-item {
  width: 140px;
  border: 1px solid #ffdeb4;
  padding: 30px 0px 0;
  position: relative;
  border-radius: 6px;
  text-align: center;
  margin: 0 10px 10px 0;
  background-color: #fff;
}

.manu-icon {
  position: absolute;
  right: 10px;
  top: 0;
}



.uplode-file-item img {
  max-height: 50px;
  margin: 0 auto 30px;
}



.uplode-file-footer {
  text-align: left;
  padding: 4px 10px;
  background: #f8ebeb;
}

.uplode-file-footer p {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}

.uplode-file-footer span {
  display: block;
  color: #969696;
  font-size: 14px;
  line-height: 15px;
}



/* Notification window styling */
.chat-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 250px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    font-family: Arial, sans-serif;
}

.chat-notification p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

/* Buttons container */
.chat-notification-buttons {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

/* Accept button styling */
.accept-btn {
    padding: 8px 16px;
    color: white;
    background-color: #90ee90; /* Light green */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.chat-accept-btn:hover {
    background-color: #7cc47c;
}

/* Reject button styling */
.chat-reject-btn {
    padding: 8px 16px;
    color: white;
    background-color: #f08080; /* Light red */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.chat-reject-btn:hover {
    background-color: #e06060;
}


@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s ease-in-out;
  animation-iteration-count: 2; /* Shake once */
}


.chats-header-dropdown{
  position: relative;
  display:block;
}
#chats-header-menu{
  position: absolute;
  background-color: white;
  padding-left: 10px;
  border: 1px solid #f8f9fc;
  width:250px;
  height:300px;
  overflow-y: scroll;
  box-shadow: 1px 1px 1px grey;
  z-index:100;
}
