@import url('https://fonts.googleapis.com/css?family=Arial|Courier|Georgia|Impact|Tahoma|Times+New+Roman|Verdana');
.editor {
      border: 1px solid #ccc;
      padding: 10px;
      min-height: 200px;
      max-width: 800px;
      margin-bottom: 20px;
      position: relative;
    }

    img {
      max-width: 100%;
    }

    .resizer {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #000;
      z-index: 10;
    }

    .resizer.top-left { top: -4px; left: -4px; cursor: nwse-resize; }
    .resizer.top-right { top: -4px; right: -4px; cursor: nesw-resize; }
    .resizer.bottom-left { bottom: -4px; left: -4px; cursor: nesw-resize; }
    .resizer.bottom-right { bottom: -4px; right: -4px; cursor: nwse-resize; }
.toolbar {
      border: 1px solid #ccc;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .editor {
      border: 1px solid #ccc;
      padding: 10px;
      min-height: 300px;
      position: relative;
    }
    .btn-group{
      display: inline;
    }

  /*  .toolbar select, .toolbar input, .toolbar button {
      padding: 5px;
      font-size: 14px;
    }*/

    .toolbar button {
      cursor: pointer;
      /*border: #cccccc;*/
      /*background-color: #cccccc;*/
    }

    .editor code {
      background-color: #f5f5f5;
      padding: 2px 4px;
      border-radius: 4px;
      font-family: monospace;
    }

    .editor table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 10px;
    }

    .editor table, .editor th, .editor td {
      border: 1px solid #000;
    }

    .editor th, .editor td {
      padding: 5px;
      text-align: center;
      position: relative;
    }

    .context-menu {
      display: none;
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      z-index: 1000;
    }

    .context-menu button {
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      background: none;
      cursor: pointer;
    }

    .context-menu button:hover {
      background-color: #f0f0f0;
    }

    .resize-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      cursor: nwse-resize;
    }

    .editor code {
        display: block;
        background-color: white;
        color:black;
        padding: 8px;
        font-family: monospace;
        white-space: pre-wrap;
        margin: 5px 0;
        border-radius: 4px;
        border: 1px solid blue;
    }

.ql-formats{
  height:40px;
}
    /**************. QUILL *********/
    /* Quill Editor Container */
.ql-container {
  font-family: Arial, sans-serif; /* Change font */
  font-size: 14px; /* Change font size */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border style */
  padding: 10px; /* Padding around content */

}

/* Quill Editor Toolbar */
.ql-toolbar {
  background-color: #f4f4f4; /* Toolbar background color */
  border: 1px solid #ddd; /* Toolbar border color */
  border-bottom: none; /* Remove bottom border */
  border-radius: 8px 8px 0 0; /* Rounded corners for the top of the editor */
}

/* Quill Editor Buttons */
.ql-toolbar .ql-picker {
  color: #555; /* Picker button color */
}

.ql-toolbar .ql-picker-label {
  border-radius: 4px; /* Rounded corners for dropdowns */
  padding: 5px; /* Padding inside picker buttons */
}

.ql-toolbar .ql-stroke {
  stroke: #333; /* Icon color for stroke-based icons */
}

.ql-toolbar .ql-fill {
  fill: #333; /* Icon color for fill-based icons */
}

.ql-toolbar .ql-active .ql-stroke {
  stroke: #007bff; /* Active icon color */
}

.ql-toolbar .ql-active .ql-fill {
  fill: #007bff; /* Active fill color */
}

/* Custom style for the editor area */
.ql-editor {
  background-color: #fff; /* Editor background color */
  color: #333; /* Text color */
  line-height: 1.6; /* Line height for text */
  max-height:60vh;
  overflow-y: auto;
}

.ql-editor td{
  border: 1px solid black;
  width:60px;
}
/* Custom style for placeholder text */
.ql-editor.ql-blank::before {
  color: #999; /* Placeholder text color */
  font-style: italic; /* Placeholder text style */
}

/* Custom scrollbars */
.ql-editor::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: #aaa; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded scrollbar thumb */
}

  #toolbar-container .ql-font span[data-label="Sans Serif"]::before {
    font-family: "Sans Serif";
  }
  
  #toolbar-container .ql-font span[data-label="Inconsolata"]::before {
    font-family: "Inconsolata";
  }
  
  #toolbar-container .ql-font span[data-label="Roboto"]::before {
    font-family: "Roboto";
  }
  
  #toolbar-container .ql-font span[data-label="Mirza"]::before {
    font-family: "Mirza";
  }
  
  #toolbar-container .ql-font span[data-label="Arial"]::before {
    font-family: "Arial";
  }
  /* Set content font-families */
  
  .ql-font-inconsolata {
    font-family: "Inconsolata";
  }
  
  .ql-font-roboto {
    font-family: "Roboto";
  }
  .ql-font-helvetica{font-family: "Helvetica";}
  .ql-font-comic-sans{font-family: "comic-sans"}
  .ql-font-mirza {
    font-family: "Mirza";
  }
  
  .ql-font-arial {
    font-family: "Arial";
  }

  .ql-size {
    font-size: inherit; /* Ensure the size is inherited from the data-value */
  }

  .ql-size-14px{font-size: 14px}
  .ql-size-16px{font-size: 16px}
  .ql-size-18px{font-size: 18px}
  .ql-size-20px{font-size: 20px}
  .ql-size-22px{font-size: 22px}
  .ql-size-24px{font-size: 24px}
  .ql-size-26px{font-size: 26px}
  .ql-size-28px{font-size: 28px}
  .ql-size-30px{font-size: 30px}
  .ql-size-32px{font-size: 32px}
  .ql-size-34px{font-size: 34px}
  .ql-size-36px{font-size: 36px}
  /*.ql-snow .ql-editor h1 {font-size: 2.5rem;}
  .ql-snow .ql-editor h2 {font-size: 1.75rem;}
  .ql-snow .ql-editor h3 {font-size: 1rem;}*/

  

  /*.ql-size[data-value="12px"] { font-size: 12px; }
.ql-size[data-value="14px"] { font-size: 14px; }
.ql-size[data-value="16px"] { font-size: 16px; }
.ql-size[data-value="30px"] { font-size: 30px !important; } */