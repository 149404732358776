.sla-box{
	border: 1px solid #dee2e68f;
	border-radius: 5px;
}
.time-wrapper{
	width:153px;
	height:35px;
	background-color: white;
	border-radius:  5px;
	border: 1px solid black;
}
.time-input{
	width:40px;	
	height:20px;
	padding-left:10px;
	
	/**/
	outline:none;
}
.time-input:focus{
	border:1px solid red;
	border-radius: 3px;
}
.duration-picker-container{
  font-size: 14px;
}

.duration-picker-container select{
  width: 45px;
  display: inline-block;
  height: 26px;
  padding: 0;
  box-sizing: content-box;
  border-radius: 3px;
  margin-left: 10px;
  background: #fff;
  border: 1px solid #e1e1e1;
  font-size: 13px;
}

.sla-create-table tbody tr:nth-child(even){
	background-color: #f8f9fc;    
}
.sla-create-table thead tr:nth-child(odd){
	background-color: #f8f9fc;    
}
.sla-dropdown-content{
  width:780px;
  position:absolute;
  max-height:300px;
  overflow-y: auto;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important;    
}
.sla-dropdown-content a:hover{
  background-color: #eeeeee;;

}
.sla-dropdown-content a {
  color: black;
   padding: 10px 10px;
  text-decoration: none;
  display: block;
  z-index: 100 !important;
  position:relative;
  background-color: white;
  width:780px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis; 
  font-size:14px;
  font-weight: 600;
  cursor: pointer;
  font-family: Roboto;
}
.sla-condition-dropdown-content{
  width:100%;
  position:absolute;
  max-height:300px;
  overflow-y: auto;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important;    
}
.sla-condition-dropdown-content a:hover{
  background-color: #eeeeee;;

}
.sla-condition-dropdown-content a {
  color: black;
   padding: 10px 10px;
  text-decoration: none;
  display: block;
  z-index: 100 !important;
  position:relative;
  background-color: white;
  width:350px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis; 
  font-size:14px;
  font-weight: 600;
  cursor: pointer;
  font-family: Roboto;
}
.sla-condition-input {
  outline:none;  
  box-sizing: border-box;
  width:350px;
  height:33px;
  display: block;
  font-size: 12px;
}
.sla-create-wrapper{	
	/*padding:3%;
  padding-top: 3%;
	background-color:white;
	height: calc(100vh - 135px);
	overflow-y: auto;
	margin-left:50px;
	width:75%;
	border-bottom:1px solid #F5F5F5;
  border: 5px solid #F5F5F5;*/
}
.business-calender-create-button{
  font-weight:bolder; 
  font-size:14px;
  padding-left:30px;
  padding-right:30px;
  position:absolute;
  margin-left:25%;
  bottom:3px;
}
.business-calender-cancel-button{
  font-weight:bolder; 
  font-size:14px;
  padding-left:30px;
  padding-right:30px;
  position:absolute;
  margin-left:5%;
  bottom:3px;
}


.busines-calender-create-wrapper{  
  padding-top: 1%;
  background-color:white;
  height: calc(100vh - 75px);
  overflow-y: scroll;
  margin-left:50px;
  width:75%;
  border-bottom:1px solid #F5F5F5;
  border: 5px solid #F5F5F5;
}
@media only screen and (max-width: 1000px) {
  .sla-create-wrapper{
    margin-left:0px;
    padding-left:5px;
    width:100%;
  }
  .business-calender-create-right-box{
    display: none;
  }
  .busines-calender-create-wrapper{
    width:100%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 810px) {
  .sla-create-wrapper{
    overflow-y: auto;
  }
}

@media only screen and (max-width: 620px) {
  .business-calender-create-button{
    margin-left: 25%;
    padding-left:10px;
    padding-right:10px;
  }
  .sla-create-wrapper .container{
    margin-left: 0px; 
  }
  .business-calender-cancel-button{
    margin-left: 1px;
    padding-left:1px;
    padding-right:10px;
  }
}
