@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.ticket-display-main-body{
  width:calc(100% - 525px);
  min-width: 645px !important;  
  height:100%;
  left: 75px;
  top:0px;
  color:#183247;
  position:absolute;
  background-color: #f4f5f7;
  overflow-x: auto;
  overflow-y:auto;
  line-height: 1.5;
  letter-spacing: .1px;
  font-weight: 400;
  z-index:100;
}
input[type="text"]:focus, 
textarea:focus {
    outline-color: grey; /* Change outline color on focus */
}
.Ticket-Left-Box {
  width: 450px;
  top:0px;
  height: 100%;
  box-shadow: 0px 4px 6px rgb(51 51 51 / 20%);
  overflow-y: scroll;
  padding-left:3px;
  background-color:  white; 
  padding:20px; 
  position:absolute;
  right:0px;
  z-index: 1000 !important;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",sans-serif,Helvetica,Roboto;  
}
.ticket-summary-ellipsis{
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2; /* number of lines to show */
   line-height: 1.5em;        /* fallback */
   max-height: 3em; 
   color:#0d6efd;
   width: 250px;
   white-space: nowrap;
   cursor:pointer;
}
.ticket-filter-frame {
  background-color: #f8f9fc;;
  padding-left: 10px;
  /*margin-right: 2%;*/
  margin-left:0%;
  border-radius:10px;
  height:100%;
  width:500px;
  min-width: 300px;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0px 4px 6px rgb(51 51 51 / 20%);
  position: relative;
}
.ticket-filter-close-button-wrapper{  
  float:right;  
  text-decoration: none;
  color:black;
  font-size: 30px;
  position: relative;
  bottom:10px;
  right:10px;
}
.ticket-summary{
  padding-left:5px;
  padding-right: 10px;
  padding-top:5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-left:1%;
  margin-right: 2%;
  font-weight: 600;
  font-size:16px;
  font-family: BogleWeb,MyriadPro,Helvetica Neue,Helvetica,Arial,sans-serif;
}
.ticket-header-right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ticket-header-right button {
  margin-right: 10px;
}

.ticket-header-right .btn-icon {
  padding: 10px 4px;
  background-color: rgb(248, 249, 250);
  border-radius: 6px;
}

.ticket-header-right .btn-icon:hover {
  background-color: rgb(211, 212, 213);
}
.btn-icon img {
  transform: rotate(90deg);
}
.right-Frame {
  width: 99%;
}

.main-table {  
  margin: 12px;
  width: calc(100% - 24px) !important;
}

.main-table thead tr th {
  background-color: #f8f9fc;
  padding: 15px;
  
}

.main-table tbody tr:nth-child(even) {
  background-color: #f8f9fc;
}

.main-table tbody td {
  font-size: 14px;
  color: #000;
  padding: 15px;
}

.main-table tbody td p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  max-width: 150px;
}
#ticket-ticket-close-button{
height: 10px;
width:10px;
}

.Ticket-Page .ticket-middle-box {
  flex: 1;
  height: 100%;
  width:100%;
  overflow-y: auto;
  overflow-x: auto;
  margin-top:1px;
  background-color: #f9f9f9;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  white-space: nowrap;;
}
.Ticket-Page {
  display: flex;  
  overflow:auto;
}
.ticket-wrapper{
  /*background-color:#EEEDED;*/
  display:flex;
  flex-direction: column;
  padding:0;
}



.Ticket-Page .ticket-contact-window {
  width: 30%;
  box-shadow: -2px 0px 6px rgb(51 51 51 / 10%);
  max-width: 320px;
  padding: 0 10px; 
  border-radius: 0 15px 15px 0;
  overflow-y: scroll;
  height: 90%;
}


.ticket-text {
  font-weight: bolder;
  color: #454242;
  margin: auto;  
}

.User-Page .Card-info {
  margin-bottom: 30px;
}

.Ticket-lastlogin {
  color: rgb(255, 0, 0);
  font-weight: 500;
  opacity: 80%;
}
.user-wrapper {
  margin-top: 30px;
}
#ticket-ticket-close-button{
height: 10px;
width:10px;
}

.applied-job {
  margin: 0 15px;
}

.job-info {
  padding: 10px;
  border-radius: 5px;
  background: #e6e6e6;
}

.job-info h6 {
  font-size: 18px;
  margin: 0 0 3px;
}

.job-info p {
  font-size: 15px;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 15px;
}

.applied-job label {
  font-size: 15px;
  margin-bottom: 5px;
}

.job-info p span {
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #333;
  margin: 0 10px;
  border-radius: 50%;
}

.job-status-wrapper {
  margin: 10px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 7px;
}

.job-status-title {
  display: flex;
  justify-content: space-between;
}

.job-status-wrapper {
  margin: 10px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 7px;
}

.job-status-wrapper .fill {
  background-color: #0073f7;
  color: #ffffff;
}

.job-status-wrapper .fill-end {
  background-color: #0073f7;
  border-radius: 0 33% 33% 0;
}

.job-status-wrapper span {
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #333;
  margin: 0 10px;
  border-radius: 50%;
  /* background-color: #0073f7; */
}

.job-status-wrapper ul {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  background-color: #f3fcff;
  overflow: hidden;
}
.Screening {
  display: flex;
  align-items: center;
}
.job-status-wrapper li {
  flex: 1;
  border-right: 1px solid #e6e6e6;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}

.job-status-wrapper li:last-child {
  border-right: 0;
}



.ticket-list-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 20px;
}

.ticket-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(230 230 230);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-icon img {
  max-width: 20px;
  max-height: 20px;
}

.ticket-list-item div > span {
  display: block;
  line-height: 17px;
  color: #333;
  font-size: 14px;
}

.ticket-wrapper textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
}
.ticket-comment-header{
  background-color: white;
  padding: 30px;
  box-shadow: 1px 1px #dee2e68f;
  border: 1px solid  #e6e6e6;
  border-radius: 10px;
  margin-left:20px;
  margin-right:20px;
   -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  background-color: white;  
}


.ticket-comment {
      background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #62748F;
  margin-top:1px;
  padding-bottom: 50px;
   /*padding-left: 30px; */
/*  width:100%;
  padding-left: 30px; 
  padding-right: 30px; 
  padding-top: 30px; 
  padding-bottom: 50px; 
   -webkit-box-shadow: 0 0 5px 5px #eeeeee;
        box-shadow: 0 0 5px 5px #eeeeee; 
  background-color: white;
  border-bottom:1px solid #ADD8E6;*/
}
.ticket-comment-header p{
  padding:10px;
  background-color: white;
  margin-top:20px;  
  font-size:13px;
  font-weight:500;
  font-family: Verdana,Arial,Helvetica,sans-seriff,Roboto,Poppins;
}
.ticket-comment p{

  padding:10px;
  margin-top:20px;  
  font-size:13px;
  font-weight:500;
  line-height: 1.5;
  font-family: Verdana,Arial,Helvetica,sans-seriff,Roboto,Poppins;
}
.reply-box-wrapper{
  width:100%;
  box-shadow: 0 0 5px rgb(51 51 51 / 21%);
  border-radius: 10px;
  overflow: hidden;
  display: block;
  bottom:0%;
  background-color: white;
  margin-top:10px;
  margin-left: 10px;
  margin-bottom: 50px;
}

.reply-box-header {
  padding: 5px 10px;
  color: #ffff;
  border-bottom: 1px solid #e6e6e6;;
}
.reply-box-header-row {
  display: flex;
  align-items: center;
}
.reple {
  display: inline-flex;
  align-items: center;
  padding: 0 20px 0 23px;
  border-right: 1px solid #fff;
  position: relative;
}

.chatbibox-header {
  padding: 5px 10px;
  background: #000;
  color: #ffff;
}

.reple {
  display: inline-flex;
  align-items: center;
  padding: 0 20px 0 23px;
  border-right: 1px solid #fff;
  position: relative;
}

.chatbibox-header-row {
  display: flex;
  align-items: center;
}

img.arrow-down {
  position: absolute;
  right: 0;
  top: 4px;
}

.reply-icon {
  position: absolute;
  left: 0;
  max-width: 20px;
}

.from-chatbibox {
  padding: 0 0px 0 15px;
  /*display: flex;*/
  /*justify-content: space-between;*/
  flex: 1;
}

.from-chatbibox b {
  margin: 0 5px;
}

.from-chatbibox img {
  max-width: 20px;
  margin-left: auto;
}

.ticket-comment-to {
  padding: 8px 10px;
  /*border-bottom: 1px solid #e6e6e6;*/
  background-color: white;
  color:black;
  width:auto;
  font-size: 16px;
  font-weight: 500;
}

.ticket-comment-to input {
  padding: 0;
  border: 0;
}

.ticket-comment-to input:focus {
  outline: 0;
  border: 0;
}

.chatbibox-footer {
/*  display: flex;
  flex-direction: row;*/
  align-items: center;
  padding:10px;
}

.chatbibox-footer ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none
}
.chatbibox-footer ul li{
  margin: 15px;
}
.chatbox-attachment-img{
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left:10px;
}
.add-comment-button {
  padding-top:5px;
  padding-bottom:5px;
  padding-left:10px;
  padding-right:10px;
  color:white;
  background-color: rgb(49, 68, 105);
  opacity:95%;
  text-align:center;
  border-radius:5px;
  margin-top: 5%; 
  box-shadow:2px 2px 2px 2px;
  font-family: sans-serif;  
}

.ss-button img {
  max-width: 12px;
  margin: 0 10px 0 0;
  width:auto;
}


.Ticket-Left-Box .Content {
  color: rgb(156, 152, 152);
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 30px;
  padding-left:0;
}

.Content {
  padding-left: 0;
}

.ticket-middle-box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.ticket-middle-box-header .Card-info {
  flex: 1;
}


.comment-header-img {  
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

img {
  width: auto;
  max-width: 100%;
}

.comment-header-info {
  margin-top: 5px;
  font-size:14px;
}

.comment-header-info strong {
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.comment-header-info div {
    font-size: 12px;
    color: #555;
}

.ticket-create-form{
  /*overflow-y: scroll;*/
  /*padding: 30px;*/
  min-width:600px;
  max-width:1000px;
  width:75%;
  height: 100%;
  right:0%;
  top:0%;
  position:fixed;   
  border-radius: 10px;
  box-shadow: -2px 0px 6px rgb(51 51 51 / 10%);
  background-color: white;
  z-index: 100 !important;  
  transition-timing-function: ease-out;
  background-color: #fdfdfd;
}
..ticket-create-form{
  font-family:Helvetica,sans-serif,Lato,Roboto;
  color:rgb(225, 238, 242,1);
  font-size:14px;
}
.ticket-create-form-field{
  width:100%;
  margin-top:2%;
}
.create-ticket-closebtn{
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 30px;
  margin-left: 10px;
  color: black;
  text-decoration: none;
}
.create-ticket-line{
  right:5%;
  position: relative; 
  width:90%;
  height:10px;
  content: "";
  border-bottom: 1px solid rgb(49, 68, 105);
  margin: auto;  
}
.create-ticket-form-label{
  font-weight: 500;
  font-size:14px;
  color:#2e2e2e;
}
.ticket-right-window-label{
  margin-top:10px;
  font-size: 16px;
  font-weight: 500;
  color:#42526e;
  /*font-family: Helvetica,sans-serif,Lato;*/
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif
}
.contact-wrapper {
  padding: 20px 8px;
  border-top: 1px solid #e6e6e6;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-top: 2px;
}

.contact-wrapper h5 {
  font-size: 19px;
  margin: 0 0 15px;
}

.ticket-contact-list-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 20px;
}

.contact-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(230 230 230);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-icon img {
  max-width: 20px;
  max-height: 20px;
}

.ticket-contact-list-item div > span {
  display: block;
  line-height: 17px;
  color: #333;
  font-size: 14px;
}

.contact-wrapper textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.ticket-pagination {
  right:1%;
  bottom:0%;
  position: fixed
}
.ticket-pagination a {
  color: black;
  float: left;
  padding: 2px 8px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
}

.ticket-pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.create-ticket-dropdown-content{
  width:90%;
  position:absolute;
  max-height:300px;
  overflow-x: auto;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important;
  border: 1px solid #DAD9D2; 
  cursor:pointer; 
}
.create-ticket-dropdown-content a {
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  z-index: 100 !important;
  position:relative;
  background-color: white;
  width:100%;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis; 
  font-size:14px;
  font-weight: 500;
  color:rgb(44, 92, 197);
}
.create-ticket-dropdown-content a:hover{
  background-color: #eeeeee;;
}

.create-ticket-filter-dropdown-content{
  width:100%;
  position:absolute;
  max-height:300px;
  overflow-x: auto;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
        box-shadow: 0 0 10px 10px #eeeeee; 
  z-index:1000 !important;
  border: 1px solid #DAD9D2; 
  cursor:pointer; 
}
.create-ticket-filter-dropdown-content a {
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  z-index: 100 !important;
  position:relative;
  background-color: white;
  width:100%;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis; 
  font-size:14px;
  font-weight: 500;
  color:rgb(44, 92, 197);
}
.create-ticket-filter-dropdown-content a:hover{
  background-color: #eeeeee;;
}

.display-ticket-dropdown-content{
  position:absolute;
  max-height:200px;
  overflow-y: auto;
  z-index:100;
 -webkit-box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196);
  box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196); 
}
.display-ticket-dropdown-content a {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
  z-index: 100 !important;
  position:relative;
  background-color: white;
  width:100%;
  cursor: pointer;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, "Helvetica Neue", sans-serif;
  padding-left:10px;
}
.display-ticket-dropdown-content a:hover{
  background-color: #eeeeee;
  font-weight: 600;  
}

#input {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    background-color: white;
    background-color: -moz-field;
    border: 1px solid darkgray;
    box-shadow: 1px 1px 1px 0 lightgray inset;  
    font: -moz-field;
    font: -webkit-small-control;
    margin-top: 5px;
    padding: 2px ;
    height: 30px;         
    border-radius: 5px; 
    display:flex; 
}
.ticket-header1 {
  align-items: center;
  background: rgb(250,251,252);
  display: flex;
  width:100%;
  height:100%;
}
.tickets-header-dropdown{
  position: relative;
  display:block;
}
#tickets-header-menu{
  position: absolute;
  background-color: white;
  padding-left: 10px;
  border: 1px solid #f8f9fc;
  width:300px;
  height:400px;
  overflow-y: scroll;
  box-shadow: 1px 1px 1px grey;
  z-index:100;
}
.ticket-search-wrapper {
  position: relative;
  margin-left: 10%;
  border-bottom: 1px solid #c4c4c4;
  width:30%;
}
.ticket-search-wrapper input:focus{
  outline: none;
}
.ticket-search-wrapper img {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 20px;
}
.ticket-search-input{
  border: 0;
  padding: 8px 10px 8px 40px;
  box-sizing: border-box;
  border-radius: 22px;
  width: 100%;
  outline: none;
  background-color: rgb(250,251,252);

}
.ticket-search-input:focus-within{
  outline: none;
}

.view div {
  display: block;
}

.view form {
  display: none;
}

.edit div {
  display: none;
}

.edit form {
  display: block;
}
.attachment-outline{
    border-radius: 1px;
    display: flex;
    width:200px;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;  
    margin-left:1%;  
    position: relative;   
    padding:5px;
    background-color: white;
    font-weight:bold; 
    margin:10px;
    /*border: 1px solid   #999999;*/
    border-radius: 5px;
    border-left:1px solid rgb(14, 173, 255);
    /*border-bottom:1px solid #6990F2;*/
    /*border-bottom:1px solid #0d6efd;*/
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow:0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196);
    /*box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196) ;*/
    /*background: #E9F0FF;*/
    /*border: 1px solid rgba(0,0,0,.14902);*/
} 
.attachment-outline:hover{
 
}
.attachment-file-icon{
 height: 35px;
 width: 55px; 
}
.attachment-download-icon{
 height: 25px;
 width: 25px; 
 padding: 7px;
 cursor: pointer;
 }
.attachment-name{
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  width:190px;
  max-width: 190px;
  margin-left: 10px;
  color: #041e42;
  font-weight: 500;
  text-decoration: none;
  display: inline-block
}
.ticket-assignee-box{
  position:relative;
  margin-top: 10px;
  
}
.assignee-box-edit{
  display: none;
}
.priority-box-edit{
  display: none;
}
.status-box-edit{
  display: none;
}
.team-box-edit{
  display: none;
}

.ticket-left-box-assignee-input-box{
  border-right:none;
  border-left:none;
  border-top:none;
  font-size: 12px;
  width:70%;
  outline:none;
  margin-left:5%;
  background-color:  white;
}

.ticket-left-box-assignee-input-box:hover{
  outline:none;
}
.assignee-input {   
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
}
.ticket-contact-display-header-img{
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  background-color: #333;
  margin-top:5%;
  
}


.priority-badge{
  display: inline-block;
  color:white;
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;    
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
}
.priority-div-filter{
  display:inline-block;
  height:10px;
  width:10px;
  margin-right:5px;
}
.priority-checkbox{
  height:16px;
  width:16px;
}
.status-checkbox{
  height:16px;
  width:16px;
}
.priority-CRITICAL{
  background-color: #dc3545;
  padding-left:20px;
  padding-right:20px;  
}
.priority-ESCALATED{
  background-color: #dc3545;
  padding-left:20px;
  padding-right:20px;  
}
.priority-HIGH{
  color: white;
  background-color: #ff7400;
  padding-left:20px;
  padding-right:20px;
}
.priority-LOW{
  color: #fff;
  background-color: #28a745;
  padding-left:20px;
  padding-right:20px;  
}
.priority-MEDIUM{
  color: #fff;
  background-color: #007bff;
  padding-left:20px;
  padding-right:20px;  
}
.ticket-display-button{
  font-size: 14px;
  background-color: rgb(76, 120, 237);  
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right:1px solid #cccccc;;
  border:none;
  outline: none;
  font-family: sans-serif;
  color:white;

}
.ticket-display-button:hover{
  background-color: #2559E5
}
.ticket-display-button-wrapper{
  /*background-color: #dee2e68f;*/
  background-color: rgb(250,251,252);
  display:inline-flex; 
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  margin-left:25%;
  margin-top:10px;
  margin-bottom:10px; 
}
.ticket-create-closebtn{
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 15px; 
  right-padding:1px; 
  color: black;
  text-decoration: none;
}
.attachment-closebtn{
  top: 0;
  right: 5px;
  font-size: 15px; 
  right-padding:1px; 
  color: black;
  text-decoration: none;
}
.comment-edit-icon{
  display:inline-block;
  float:right;
  cursor:pointer;
  width:200px;
}
#spinner
    {
        /*background: url("image") repeat scroll #AAA;*/
        opacity: 0.3;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index:2000;
    }

.ticket-tags-wrapper{
  margin-left:5%;
  font-size:16px;
  color: black;
  width:90%;
  border: 1px solid black;
  max-height:200px;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: wrap;
}
.ticket-tag{
  display: inline-block;
  color:white;
  padding-right: 5px;
  padding-left: 5px;
  padding-top:2px;
  padding-bottom:2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;    
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  color:white;
  background-color:grey;
}







.wrapper{
  width: 90%;
  background: #fff;
  border-radius: 3px;   
}
.wrapper .content{
  border-radius: 3px;
  position: relative;
}
.content ul{  
  display: flex;
  flex-wrap: wrap;
  padding: 1px;
  border: 1px solid black;
  margin-bottom: 0px

}
.content ul  li{
  font-weight:500;
  font-size: 16px;
  padding-top: 3px;
  color: #333;
  margin: 2px 1px;
  list-style: none;
  border-radius: 5px;
  background: #F2F2F2;  
  border: 1px solid #e3e1e1;
  border-radius: 3px; 
}
.content ul li i{
  color: #808080;
  margin-left: 8px;
  font-size: 12px;
  border-radius: 50%;
  background: #dfdfdf;
  justify-content: center;
}
.content ul input{
  flex: 1;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 12px;
}
.ticket-filter-button{
  margin-top: 30px;  
  padding-left:15px;
  padding-right:15px;
  padding-top:5px;
  padding-bottom:5px;
  bottom:10px;

}

.comment-edit-dropdown{
    position: absolute;
    top: 20px;
    right: 170px;
    width:100px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: none; /* Toggle display with JS */
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,1);
        box-shadow: 0 0 2px rgba(0,0,0,1); 
    z-index: 1000 !important*/
/* position: absolute; 
 width:100px; 
 left:-50px;
 text-align:center;
 font-size: 14px;
 font-weight: 500;
 display:none;
 background-color: white;
 z-index: 1000 !important*/
}
.comment-edit-icon:hover .comment-edit-dropdown{
  display:block;
}
.comment-edit-dropdown:hover{
  display:block;
}
.comment-edit-dropdown div:hover{
  background-color: #eeeeee;
}
.ticket-quick-view{
  position: absolute;
  top:10%;
  left:20%;
  height:80%;
  width:50%;
  z-index: 1000 !important;
  -webkit-box-shadow: 0 0 10px 10px #eeeeee;
  box-shadow: 0 0 10px 10px #eeeeee;
  overflow-y: auto;
  display: none; 
}
.summary_edit{
  display: none;
}
.ticket-reopened{
  color: #ffa700;
  padding-left:10px;
  padding-right:10px;
  padding:5px;
  color:white;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;

}



/* ticket history css */


.history-wrapper ul, .history-wrapper  li{
  list-style: none;
  padding: 0;
}

.history-container{
  display: flex;
  background-color: white;
  justify-content: flex-start;

}
.history-wrapper{
  background: white;
  /*padding: 2rem;*/
  border-radius: 15px;
  width:99%;
}
.sessions{
  margin-top: 2rem;
  border-radius: 12px;
  position: relative;
}
.sessions li h5{
  font-size: 16px;
}
.sessions li{
  padding-bottom: 1.5rem;
  border-left: 1px solid #abaaed;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  &:last-child{
    border: 0px;
    padding-bottom: 0;
  }
  &:before{
    content: '';
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid #4e5ed3;
    box-shadow: 3px 3px 0px #bab5f8;
    box-shadow: 3px 3px 0px #bab5f8;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 0px;
  }
}
.sessions .time{
  color: #2a2839;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  @include mobile-and-up{
    font-size: .9rem;
  }
  @include mobile-only{
    margin-bottom: .3rem;
    font-size: 0.85rem;
  }

}
.sessions p{
  color: black;
  font-family: 'Poppins', sans-serif;
  
  margin-top:0.4rem;  
  @include mobile-only{
    font-size: .9rem;
  }
}
.sessions p a{
  text-decoration: none;
  font-size: 14px;
}

.ticket-merge-box{
  z-index: 10000 !important;
  min-width:600px;
  max-width:1000px;
  width:75%;
  height: 100%;
  position: absolute;
  right:0px;
}
.ticket-merge-box input{
  
}
.ticket-merge-message-box{
  margin-top:10px;
  border:1px dotted black;
  text-align: center;
  padding-top:20px;
  padding-bottom:20px;
  background-color:   #f6f3ef;
  font-weight:600;
  font-size:0.9rem;
}
.ticket-display-header-buttons-margin{
  margin-left: auto;
}
.ticket-details-button{
  display: none;
  cursor:pointer;
}
@media only screen and (max-width: 600px){
  .ticket-create-form{
    min-width: 100%
  }
}

/*@media only screen and (max-width: 1220px) {
  .Ticket-Left-Box{
    width:400px;
  }
  .ticket-display-main-body{
    width:calc(100% - 475px);
  }
}
@media only screen and (max-width: 1120px) {
  .Ticket-Left-Box{
    width:350px;
  }
  .ticket-display-main-body{
    width:calc(100% - 425px);
  }
  
}*/






.reply-box-container {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    bottom:1%;
    margin-bottom: 50px;
    margin-top: 50px
}

/* Toolbar buttons */
.reply-box-toolbar {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
    margin-bottom: 8px;
    align-items: center;
}

.reply-box-toolbar-btn {
    background: none;
    border: none;
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
    padding: 5px;
}

.reply-box-toolbar-btn:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
}

/* Text input area */
.reply-box-message-input {
    width: 100%;
    height: 300px;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    resize: none;
    border-radius: 4px;
    /*background-color: #f9f9f9;*/
    background-color: white;
    border: 1px solid #e0e0e0;
}

/* Checkbox and additional options */
.reply-box-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.reply-box-options label {
    font-size: 14px;
}

.channel-name {
    font-weight: bold;
    color: #5e6d82;
    margin-left: 5px;
}

.icons {
    display: flex;
}

.reply-box-icon-btn {
    background: none;
    border: none;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.reply-box-icon-btn:hover {
    color: #007bff;
}
.replybox-attachment-img{
  height:17px;
  width:17px;
  cursor: pointer;
  margin-right: 10px;
}
.create-ticket-toolbar{
  width:90%;
  display : flex;
  justify-content:left;
  gap:10px;
  padding:5px;
  border:1px solid #f4f4f4;
  align-items: baseline;
}
.create-ticket-toolbar button {
  border: none;
}

.ticket-create-editor ol, ul, li, font, div{
  padding:0;
}


.no-list-style ol, .no-list-style li{
  list-style: disc;
}

