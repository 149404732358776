/* Base styles for modal */
.kb-modal {
    display: block; /* Hidden by default */
    position: fixed;
    z-index: 1000; /* Sit on top of other elements */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if modal content exceeds screen */
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
}
.ql-align-right {
  text-align: right;
}

/* Modal content */
.kb-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Default width for smaller devices */
    max-width: 600px; /* Limit maximum width */
    margin: 0 auto; /* Center horizontally */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    top: 10%; /* Position it slightly from the top of the screen */
}

/* Modal close button */
.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #aaa;
    height:10px;    
    text-align: top;
}

.close:hover {
    color: #000;
}
.category-dropdown-line{
    cursor: pointer;
    max-width:90%;
    text-overflow: ellipsis;
    overflow: hidden;
    color:black;
    font-size: 16px;
    padding:10px 10px;
    white-space: nowrap;
    display: block;;
}

/* Responsive behavior */
@media (min-width: 768px) {
    .kb-modal-content {
        width: 60%; /* Centered modal box for larger devices */
        top: 20%; /* Adjust vertical position */
    }
}

@media (min-width: 1200px) {
    .kb-modal-content {
        width: 40%; /* Larger modal on big screens */
    }
}
