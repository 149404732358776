
.chat-menu-card {
    width: 200px;
    height:200px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Soft, lifted shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.chat-menu-card:hover{
 /* Slight lift on hover */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Deeper shadow */
}
.chat-menu-header {
    padding: 7px 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;  /* Ensures vertical centering */
    color: rebeccapurple;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    min-height: 50px; /* Ensure a fixed height */
}

.chat-menu-header p {
    font-weight: 500;
    color: white;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis; 
    max-width:130px;
    white-space: nowrap;
}

.chat-menu-content {
    padding: 20px;
    text-align: center;
    font-weight: 700;
    color:grey;
}

.create-chat-delete-icon {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 15px;
            height: 15px;
            background-image: url('../../../../src/img/delete.png'); /* Placeholder delete icon */
            background-size: cover;
            cursor: pointer;
}
.handle-label {
  position: absolute;
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 12px;
  color: black;
  font-weight: bold;
  text-align: center;
}

.right-label {
  top: 70%;
  right: 0px;
  transform: translateY(-50%);
}

.bottom-label {
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}
.bot-menu-card{
  color:#03091E;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 800;
  padding: 20px;
  box-shadow: rgb(228, 232, 236) 0px 9px 12px -6px;
  background-color: #fff;
  border-radius: 5px;
}
/*.bot-menu-card:hover {
    transform: translateY(-5px);
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
}*/
.bot-menu-card-select{
  color: rebeccapurple;
  border-left: 5px  solid rebeccapurple;
}
.bot-card {
    display: flex;
    align-items: center;
    gap: 15px;
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
    transition: transform 0.2s ease-in-out;
    width:90%;
    cursor: pointer;
    margin-top: 20px;
}


.bot-card.selected {
  border-color: blue;
  background-color: rgba(0, 0, 255, 0.1);
}

.bot-header {
  display: flex;
  align-items: center;

}

.bot-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}


.bot-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 255, 0.3);
}

.bot-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.bot-info {
    display: flex;
    flex-direction: column;
}
.bot-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #e3f2fd;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    overflow: hidden;
}

.bot-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Bot Info */
.bot-info {
    flex: 1;
}

.bot-name {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: #333;
}

.bot-meta {
    font-size: 12px;
    color: #666;
    margin: 5px 0;
}

/* Bot Stats */
.bot-stats {
    display: flex;
    gap: 15px;
    margin: 10px 0;
}

.stat {
    text-align: center;
}

.stat-title {
    font-size: 12px;
    color: #666;
}

.stat-value {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

/* Bot Actions */
.bot-actions {
    display: flex;
    gap: 8px;
    margin-top: 10px;
}

.btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s ease;
}

.btn:hover {
    background: #0056b3;
}
.bot-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.dummy-node {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
  border: 2px solid #4caf50;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: bold;
  color: #4caf50;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.dummy-node:hover {
  background: #4caf50;
  color: white;
}

.dummy-node-circle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #4caf50;
  color: white;
  font-size: 16px;
  font-weight: bold;
}
.node-highlight{
  border: 3px solid red;
}

.chatbot-status-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.chatbot-status-btn.active {
  background-color: #4CAF50;
  color: white;
}

.chatbot-status-btn.inactive {
  background-color: #f44336;
  color: white;
}