.subscription-display{
  display :flex;
  height:100%;
  justify-content: space-around;
  flex-direction: row;
  overflow: auto;
}
.subscription-wrapper{
	/*margin-top:3%;*/
	font-family: 'Helvetica,Amplitude,sans-seriff';
	/*-webkit-box-shadow:0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196);*/
 	/*box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196)	;*/
  min-width:400px;
  padding:40px; 
  flex-basis:40%;
  width:100%;
  padding-bottom:30px; 
  border-radius: 5px;
  /*overflow-y:auto;*/
}
.overlay {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128,128,128,0.5); /* color */
  display: block; /* making it hidden by default */
}
.spinner-center {
  position:absolute;
  top:50%;
  left:50%;
  /*transform:translate(-50%, -50%);*/
  color: black; 
  opacity: 1;
  z-index: 1000;
/*  height:35px;
  width:35px;*/
  z-index:100 !important;
}

/* Pricing section */

.pricing-plans {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  
  /*width: 100%;*/
  padding: 0px 30px 0;
}
.pricing-card {
  color: #e4e4e7;
  position: relative;
  min-width: 360px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 15px;
  border: 0px solid #d8d8d8;
  background-color: #ffffff21;
  box-shadow: 0 0 7px 0 #cecece;
  text-align: center;
}
.pricing-card:hover {
  transform: translateY(-5px) scale(1.02);
  transition: all 0.5s ease;
  /*box-shadow: 0 0 7px 0 #044caf;*/
}

.pricing-card > *:not(:last-child) {
  /*margin-bottom: 32px;*/
}

.pricing-card .heading h4 {
  padding-bottom: 0;
  color: #044caf;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 7px;
}
.pricing-card .heading p {
  color: #000;
  font-size: 16px;
}

.pricing-card .price {
  position: relative;
  color: #044caf;
  font-size: 30px;
  font-weight: bold;
}

.pricing-card .price sub {
  position: absolute;
  bottom: 14px;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}
/*.pricing-card .features{
  max-height:80%;
  overflow-y: auto;
}*/
.pricing-card .features li {
  padding-bottom: 6px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  list-style: none;
}


.pricing-card .features li i,
.pricing-card .features li strong {
  color: #e4e4e7;
  font-size: 16px;
  text-align: left;
}
.pricing-card .features li i {
  color: #044caf;
  font-weight: 700;
  font-size: 22px;
  margin: 0 15px 0 0;
}
.pricing-card .features li strong {
  padding-left: 24px;
}
.pricing-card .cta-btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #044caf;
  background-color: #044caf;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
}

.pricing-card .cta-btn:active {
  background-color: transparent;
  color: #000;
  transition: all 0.3s ease;
}
.pricing-card .price sup {
  font-size: 20px;
}
.pricing-card .price sub {
  font-size: 17px;
  color: #555;
  text-transform: ;
}
.subscription-table tbody:nth-child(2n){
  background-color: #f8f9fc;
}

/* shipping address */

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-lighter-gray);
  padding: .5rem;
  border-radius: .25rem;
}

.field__label {
  color: black;
  font-size: 0.6rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0.25rem
}

.field__input {
  padding: 0;
  margin: 0;
  /*border: 0;*/
  outline: 0;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}
.field:focus-within {
  border-color: #000;
}

.fields {
  display: grid;
  grid-gap: 1rem;
}
.fields--2 {
  grid-template-columns: 1fr 1fr;
}
.fields--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

/******* End of shipping address*/

/*@media only screen and (max-height: 635px) {
.subscription-display{
  flex-direction: column;
  overflow-y: auto
}
.subscription-wrapper{
  overflow-y: visible;
  }
}*/

