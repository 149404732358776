.topnav-status-dropdown:hover{
	background-color: #eeeeee;
  	font-weight: 800;	
}
#topnav-status-dropdown-button{
	padding:0px;
}
#topnav-status-dropdown-button::before{
	display: none;
}
#topnav-status-dropdown-button:hover{
	background-color: #eeeeee;
}
.dropdown-toggle::after{
	display: none;
}
.topnav-user-profile{
	
	/*top: 5px;	*/
	height: 45px;
	width:  60px;
	left:0px;
	/*padding-top:9px;*/
	text-align:center;
	position:absolute;
	color:white;
	align-items:center;
	cursor:pointer;
	display:flex;
	justify-content: center;
}
.topnav-user-profile-status{
	position: absolute;
	right:0px;
	color: green;
	font-size:12px;
	font-weight:bolder;
}
.user-profile-dropdown-menu{
	font-weight:bolder;
	margin-top:200px;
	right:10px;
	width:190px;
	font-size:12px;
	position : absolute;
	display:block;
	/*padding-left:20px;*/
	z-index:10;
	background-color:white;
	color:black;
	border: 1px solid #dee2e68f;
	-webkit-box-shadow:0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196);
 box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196)
	/*padding: 15px;*/
}
.user-profile-dropdown-item-menu button{
	width: 100%;
}
.user-profile-dropdown-item-menu button::before{
	background-color: #eeeeee;
}
#ssssss{	
	width:100%;
	display: inline-block;
	padding:5px;
}
#ssssss:hover{
	background-color: #eeeeee;
}
.user-profile-dropdown-item-menu > button:hover{
	background-color: #eeeeee;
}
.user-profile-dropdown-item-menu:hover{
	background-color: #eeeeee;
  	font-weight: 800;
}
.user-profile-dropdown-item-menu{
	cursor:pointer;
	width:100%;
	padding:10px;	
	padding-bottom:8px;
	background-color: white;
	font-size:14px;
	font-family: zubilo,Roboto,Helvetica,sans-serif,Lato
}
.topnav-profile-submenu{
	position: absolute;
	left: -150px;
	width: 150px;
	bottom:70px;
	background-color: white;
	display: none;
}

.topnav-profile-submenu-item{
	width: 150px;
	padding:10px;
}
.topnav-profile-submenu-item:hover{
  background-color: #eeeeee;
  font-weight: 800;
}
