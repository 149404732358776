@import url('https://fonts.googleapis.com/css2?family=Poppins');

.sidenav-drop-down-wrapper{
  position: relative;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  margin-top:20px;
  cursor: pointer;
  width:100%;
  background-color: rgb(30, 47, 82);  
}
.sidenav-drop-down{
  background-color: white;
  z-index: 10000 !important;
  display: none;
  color: #252525;
  margin-left:5px;
  font-size: 14px; 
  position:absolute;
  display: none;
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  top:5px;
  font-weight: 400;
  width:200px;
  border-radius: 5px;
  -webkit-box-shadow:0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196);
 box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196)
/*  -webkit-box-shadow: 0 0 2px rgba(0,0,0,1);
        box-shadow: 0 0 2px rgba(0,0,0,1); */
}
.sidenav-drop-down-wrapper:hover .sidenav-drop-down{
  display: inline;
}
.sidenav-drop-down-wrapper:hover .dropdown-menu{
  display: inline;
}
.sidenav-drop-down:hover{
  display: inline;
}
#dropdown-basic::after {
    display:none;
}
.sidenav-drop-down-item{
  padding:10px;
  display: block;
  color:#3c3c3c;
  text-decoration: none;
  font-weight: 600;
  font-size:14px;
  font-family: Poppins,Helvetica,sans-serif,Arial,verdana;  
}
.sidenav-drop-down-item:hover{
  font-weight: 800;
  background-color: #eeeeee;
}
.sidenav-menu{
  margin-left:25px;
  margin-top:12px;
  margin-bottom:5px;
  font-size: 14px;
  font-family: Poppins, Philosopher,Roboto, "Lato",Arial, sans-serif;;
}
.sidenav-sub-menu{
  margin-left:35px;  
  line-height: 1.5;
  font-family: Poppins,Philosopher,Roboto,Baloo;
  text-shadow: none;
}
.sidenav-sub-menu > a{
  text-decoration: none;
  font-family: Poppins,Roboto, "Lato",Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color:white; 
  line-height: 1.5; 
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}


.sidenav-menu-dropdown{
	cursor:pointer;
	float:right;
	display:inline;	
	height: 10px;
	width: 10px;
	margin-right:5px;
  color:white;
}
.sidenav-image{
  height:20px; 
  width:20px;
  cursor:pointer;
  display:inline;
  margin-left:2px;
}
.sidenav-elements{
   display: flex; 
   justify-content: start;
   flex-direction: column;
   align-items:center;
   gap:2%;
}
@media only screen and (max-height: 393px) {
  .sidenav-drop-down-wrapper{
    margin-top: 0px;
  }
  .sidenav-image{
    height:5px; 
    width:5px;
  }
}
@media only screen and (max-height: 440px) {
  .sidenav-image{
    height:15px; 
    width:15px;
  }
  .sidenav-elements{
    gap:0%;
  }
}








